import React, { Component } from 'react'
import styled from "styled-components"
import DownArrowIcon from "../Icons/DownArrowIcon"
import { MarkdownContent } from "../section"
import BreakpointUp from "../Media/BreakpointUp"
import generateHTML from '../../utils/generateHTML';
import Content, { HTMLContent } from '../Content';

const DropWrapp = styled.div`
	margin-bottom: 20px;
	cursor: pointer;
	padding:22px;
	border: 1px solid #ddd;
`
const DropButton = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
`
const DropButtonText = styled.h2`
	font-size: 20px;
	line-height: 28px;
	font-weight: 700;
	color: #020304;
	margin-bottom:0;
	width: calc(100% - 42px);
	display: inline-block;	
	${BreakpointUp.md`
		font-size: 24px; 
		line-height: 32px
	`}	

`
const DropButtonIcon = styled.div`
	width: 42px;
	height:42px;
	background-color:#EAEAEA;
	border-radius:50%;
	display: flex;
    align-items: center;
    justify-content: center;
	& svg {
		float: right;
		transform: ${props=>(props.isVisible ? "rotate(0deg)" : "rotate(-90deg)")};
		transition: all .3s ease;
		height: 14px;
		width: 12px;
	}
`
const FadeContent = styled.div`
	display: ${props=>(props.isVisible ? "block" : "none")};
	padding-top: 20px;
	padding-bottom: 30px;
`
class FadeDrop extends Component {
	constructor(props){
		super(props)
		this.state = {
			isVisible: false
		}
		this.handleFade=this.handleFade.bind(this)
	}
	handleFade() {
		const nextState = !this.state.isVisible
		this.setState({
			isVisible: nextState
		})
	}
	render() {
		const {isVisible} = this.state
		const { data } = this.props
		const PostContent = HTMLContent || Content;
		return(
			<DropWrapp isVisible={isVisible}>
				<DropButton onClick={this.handleFade} isVisible={isVisible} >
					<DropButtonText>{data.question}</DropButtonText>
					<DropButtonIcon isVisible={isVisible}>
						<DownArrowIcon fill="#999" />
					</DropButtonIcon>
				</DropButton>                           
				<FadeContent isVisible={isVisible}> 
					<MarkdownContent>
						<PostContent
              content={generateHTML(data.answer.childMarkdownRemark.html)}
            />
					</MarkdownContent>
				</FadeContent>
			</DropWrapp>
		)
	}
}

export default FadeDrop